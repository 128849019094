<template>
  <v-container fluid>
    <TableViewComponent
      title="Table clé des labels par activités"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter une clé"
      itemLabel="clé"
      itemsLabel="clés"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette clé ?"
      :loading="loading"
      :vcols="{ xl: '6', lg: '10', md: '10', sm: '12', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"

    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { ConfAppAvecIndicatorsService } from "@/service/conf/app_avec_indicators_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


export default {
  name: "TableConfAvecKeysLabelsActivities",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      entities: [],

      /**le service d'accès aux activités */
      service: null,
    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() {
      this.$router.push(routes.confAvecKeyLabelsActivities.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.entities = [];

        let entities = await this.service.getAll();

        entities.forEach((e) => {
          let entity = JSON.parse(JSON.stringify(e));
          
          entity.view = () => {
            this.$router.push(routes.confAvecKeyLabelsActivities.view.root + "/" + entity.id);
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(routes.confAvecKeyLabelsActivities.edit.root + "/" + entity.id);
          };

          entity.delete = async () => {
            try {
              await this.service.delete(entity.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression du label : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.entities.push(entity);
        });

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "clé",
          align: "start",
          sortable: true,
          value: "key",
        },
        {
          text: "label par défaut",
          align: "start",
          sortable: true,
          value: "defaultLabel",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },

  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditConf];
    },
     /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditConf, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [ RolesApplicationEnum.ReadConf] ;
    },
  },
  mounted() {
    //on instancie les services
    this.service = new ConfAppAvecIndicatorsService(this.$api.getAppAvecIndicatorsApi());

    this.load();
  },
};
</script>

<style>
</style>